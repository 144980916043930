export default class {
  constructor() {
    this.getters = {};
    this.actions = {
      setValue({ commit }, param) {
        commit("setValue", param);
      },
      pushValue({ commit }, param) {
        commit("pushValue", param);
      },
      pullValue({ commit }, param) {
        commit("pullValue", param);
      },
      pullIdValue({ commit }, param) {
        commit("pullIdValue", param);
      },
    };
    this.mutations = {
      setValue(state, param) {
        _.set(state, param.key, param.value);
      },
      pushValue(state, param) {
        const values = _.get(state, param.key, []);
        values.push(param.value);
        _.set(state, param.key, values);
      },
      pullValue(state, param) {
        const value = param.value;
        const values = _.get(state, param.key, []);
        const filtered = values.filter((item) => {
          return item !== value;
        });
        _.set(state, param.key, filtered);
      },
      pullIdValue(state, param) {
        const field = typeof param.field !== "undefined" ? param.field : "id";
        const value = param.value;
        const values = _.get(state, param.key, []);
        const filtered = values.filter((item) => {
          return Number(item[field]) !== Number(value[field]);
        });
        _.set(state, param.key, filtered);
      },
    };
  }
}
