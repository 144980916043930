import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("./pages/products/List"),
  },
  {
    path: "/cart",
    component: () => import("./pages/Cart"),
  },
  {
    path: "/checkout",
    component: () => import("./pages/Checkout"),
  },
  {
    path: "/contest",
    component: () => import("./pages/Contest"),
  },
];

export default new VueRouter({
  mode: "history",
  base: "/",
  routes,
});
