<template>
  <b-modal
    :id="modal.id"
    :title="modal.title"
    ok-title="Add to cart"
    @ok="handleAddToCart"
    @hidden="handleCloseModal"
    :ok-disabled="!submitAllowed"
    centered
    no-close-on-backdrop
  >
    <h5>{{ entry.name }}</h5>
    <div v-if="hasVariants">
      <b-form-group label="Available options">
        <b-form-radio-group
          id="item-variants"
          v-model="selected"
          name="item-variants"
        >
          <b-form-radio
            :key="v.id"
            v-for="v in variants"
            :value="v.id"
            :disabled="v.status === 'out_of_stock'"
            >{{ v.name }}</b-form-radio
          >
        </b-form-radio-group>
      </b-form-group>
    </div>
    <div class="row" v-if="hasOutOfStock">
      <div class="col">
        <p class="text-danger">Some items may be out of stock</p>
      </div>
    </div>
    <div
      v-if="entry.status !== 'out_of_stock'"
      class="row align-content-center"
    >
      <div class="col">
        <small>Qty</small><br />
        <plus-minus-button
          v-model="quantity"
          :min="1"
          :max="10"
        ></plus-minus-button>
      </div>
      <div class="col-3 align-self-center">
        <small>Rate</small><br />
        <strong>{{ $moneyfy(getRate) }}</strong>
      </div>
      <div class="col-3 align-self-center">
        <small>Amount</small><br />
        <strong>{{ $moneyfy(getAmount) }}</strong>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { EventBus } from "@/eventBus";
import PlusMinusButton from "@/components/PlusMinusButton";

export default {
  name: "VariantDialog",
  components: {
    PlusMinusButton,
  },
  data() {
    return {
      modal: {
        id: "modal-variant-dialog",
        title: "Add to Cart",
      },
      entry: {
        variants: [],
      },
      quantity: 1,
      selected: null,
    };
  },
  computed: {
    hasOutOfStock() {
      return (
        this.entry.variants.filter((v) => {
          return v.status === "out_of_stock";
        }).length > 0
      );
    },
    submitAllowed() {
      let allowed = true;
      if (this.quantity < 1) {
        allowed = false;
      }
      if (this.hasVariants) {
        if (this.selected === null) {
          allowed = false;
        }
      }
      if (this.entry.status === "out_of_stock") {
        allowed = false;
      }
      return allowed;
    },
    hasVariants() {
      return this.entry.variants.length > 0;
    },
    variants() {
      return this.hasVariants ? this.entry.variants : [];
    },
    getVariant() {
      if (this.selected !== null) {
        return this.variants.find((s) => {
          return Number(s.id) === Number(this.selected);
        });
      }
      return null;
    },
    getRate() {
      if (this.hasVariants) {
        if (this.selected !== null) {
          const v = this.getVariant;
          return v.rate;
        }
        return 0;
      }
      return this.entry.rate;
    },
    getAmount() {
      const rate = this.getRate;
      const qty = this.quantity;
      return Number(rate) * Number(qty);
    },
  },
  methods: {
    handleAddToCart() {
      const depositRate = !isNaN(this.entry.deposit) ? this.entry.deposit : 0;
      let item = {
        item_id: this.entry.id,
        rate: this.entry.rate,
        deposit_rate: depositRate,
        title: this.entry.name,
        variant_title: null,
        quantity: this.quantity,
        amount: Number(this.quantity) * Number(this.entry.rate),
        deposit_amount: Number(this.quantity) * Number(depositRate),
      };
      if (this.hasVariants) {
        const v = this.getVariant;
        const vDepositRate = !isNaN(v.deposit) ? v.deposit : 0;
        item = {
          item_id: v.id,
          rate: v.rate,
          deposit_rate: vDepositRate,
          title: this.entry.name,
          variant_title: v.name,
          quantity: this.quantity,
          amount: Number(this.quantity) * Number(v.rate),
          deposit_amount: Number(this.quantity) * Number(vDepositRate),
        };
      }
      this.addToCart(item);
    },
    handleCloseModal() {},
  },
  created() {
    EventBus.$on("init-" + this.modal.id, (payload) => {
      this.quantity = 1;
      this.selected = null;
      this.entry = payload;
      this.$bvModal.show(this.modal.id);
    });
  },
};
</script>

<style scoped></style>
