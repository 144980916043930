import Vue from "vue";
import App from "./App.vue";
import VueMask from "v-mask";

import store from "./store";

require("./bootstrap");

import "jquery";
import "bootstrap";

import "./filters.js";

import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import "./assets/scss/style.scss";

import router from "./routes";

import GlobalComponents from "@/globalComponents";
import GlobalMixins from "@/mixins/global";
import OrderMixins from "@/mixins/order";

Vue.config.productionTip = false;

/*Vue.use(VueMask, {
  placeholders: {
    X: null,
  },
});*/
Vue.use(VueMask);
Vue.use(GlobalComponents);
Vue.use(GlobalMixins);
Vue.use(OrderMixins);

new Vue({
  render: (h) => h(App),
  router,
  store,
  created() {
    this.setupValues();
  },
}).$mount("#app");
