import Helper from "@/store/helper";
const helper = new Helper();

function initialState() {
  return {
    orderType: null,
    ageVerification: "not_accepted",
    key: null,
    customer: {
      id: null,
      mobile: null,
      display_name: null,
      email: null,
      status: 1,
      address: {
        address_1: null,
        address_2: null,
        postal_code: null,
      },
    },
    cart: {
      items: [],
      totals: {
        payment: {},
        subTotal: null,
        taxTotal: null,
        taxRate: null,
        changeTotal: 0,
        adjustmentTotal: 0,
        discountTotal: 0,
        discountRate: "",
        discountType: "p",
        deliveryTotal: null,
        depositTotal: null,
        tipTotal: 0,
        grandTotal: 0,
      },
    },
  };
}

const getters = {
  ...helper.getters,
  cartItems: (state) => state.cart.items,
  totals: (state) => state.cart.totals,
  customer: (state) => state.customer,
  orderType: (state) => state.orderType,
  ageVerification: (state) => state.ageVerification,
  isLoggedIn: (state) => state.key !== null,
};
const actions = {
  ...helper.actions,
  calculateTotal({ commit }) {
    commit("calculateTotal");
  },
  resetState({ commit }) {
    commit("resetState");
  },
};
const mutations = {
  ...helper.mutations,
  calculateTotal(state) {
    const subTotal = (state.cart.totals.subTotal = state.cart.items.reduce(
      (total, s) => {
        return Number(s.amount) + Number(total);
      },
      0
    ));
    const depositTotal = (state.cart.totals.subTotal = state.cart.items.reduce(
      (total, s) => {
        return Number(s.deposit_amount) + Number(total);
      },
      0
    ));
    const taxRate = state.cart.totals.taxRate;

    const taxTotal = (Number(taxRate) * Number(subTotal)) / 100;

    state.cart.totals.subTotal = subTotal;
    state.cart.totals.taxTotal = taxTotal;
    state.cart.totals.depositTotal = depositTotal;

    state.cart.totals.grandTotal =
      Number(subTotal) + Number(taxTotal) + Number(depositTotal);
  },
  resetState(state) {
    Object.assign(state, initialState());
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
