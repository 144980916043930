<template>
  <div class="row">
    <div class="col-12">
      <h4 class="text-center">Cart</h4>
      <div class="table-responsive">
        <table class="table table-sm">
          <tbody v-if="hasCartItems">
            <tr v-for="s in cartItems" :key="s.id">
              <td>
                {{ s.title }}<br /><small>{{ s.variant_title }}</small>
              </td>
              <td>{{ s.rate }} x {{ s.quantity }}</td>
              <td class="text-right">{{ $moneyfy(s.amount) }}</td>
              <td class="text-right" style="width: 25px">
                <a
                  href="#"
                  @click.prevent="removeCartItem(s)"
                  title="Remove Item"
                  ><i class="material-icons text-danger">delete</i></a
                >
              </td>
            </tr>
          </tbody>
          <tbody v-if="!hasCartItems">
            <tr>
              <td colspan="4" class="text-center">Cart is empty</td>
            </tr>
          </tbody>
          <tfoot v-if="hasCartItems">
            <tr>
              <th colspan="2" class="text-right"><small>SUB-TOTAL</small></th>
              <th class="text-right">
                <small>{{ $moneyfy(totals.subTotal) }}</small>
              </th>
              <th></th>
            </tr>
            <tr>
              <th colspan="2" class="text-right">
                <small>TAX ({{ totals.taxRate }}%)</small>
              </th>
              <th class="text-right">
                <small>{{ $moneyfy(totals.taxTotal) }}</small>
              </th>
              <th></th>
            </tr>
            <tr>
              <th colspan="2" class="text-right">
                <small>DEPOSIT TOTAL</small>
              </th>
              <th class="text-right">
                <small>{{ $moneyfy(totals.depositTotal) }}</small>
              </th>
            </tr>
            <tr>
              <th colspan="2" class="text-right"><small>TOTAL</small></th>
              <th class="text-right">
                <small>{{ $moneyfy(totals.grandTotal) }}</small>
              </th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </div>
      <div v-if="hasCartItems" class="text-right">
        <b-button @click="handleCheckout" class="mr-2" variant="primary"
          >Checkout</b-button
        >
        <b-button @click="handleClearCart" variant="text">Clear Cart</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cart",
  methods: {
    handleClearCart() {
      const result = confirm("Are you sure to clear all items in the cart?");
      if (result === true) {
        this.$clearCart();
      }
    },
    handleCheckout() {
      this.$router.push("checkout");
    },
  },
};
</script>

<style scoped></style>
