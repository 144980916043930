import { EventBus } from "@/eventBus";

const GlobalMixins = {
  install(Vue) {
    Vue.mixin({
      data() {
        return {};
      },
      computed: {
        dateConfig() {
          return {
            dateFormat: "d/m/Y",
            altFormat: "M	j, Y",
            altInput: true,
          };
        },
        dateRangeConfig() {
          return {
            ...this.dateConfig,
            mode: "range",
          };
        },
      },
      methods: {
        $moneyfy(amount) {
          const sign = "$";
          const rounded = Number(amount).toFixed(2);
          return sign + rounded.toString();
        },
        $capitalize(str) {
          if (str === null) {
            return "";
          }
          var splitStr = str.toLowerCase().split(" ");
          for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] =
              splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
          }
          return splitStr.join(" ");
        },
        // eslint-disable-next-line no-unused-vars
        $objIsset(obj, level1, level2, ...levelN) {
          var args = Array.prototype.slice.call(arguments, 1);
          for (var i = 0; i < args.length; i++) {
            // eslint-disable-next-line no-prototype-builtins
            if (!obj || !obj.hasOwnProperty(args[i])) {
              return false;
            }
            obj = obj[args[i]];
          }
          return true;
        },
        $dateRangeDefault() {
          let days = 30;
          //console.log(this.$headMeta("date_range_days"));
          /*if (this.$headMeta("date_range_days")) {
              days = this.$headMeta("date_range_days");
          }*/
          return this.$getDateRange(days);
        },
        $getDateRange(days) {
          const format = this.$headMeta("moment_date_format");
          // eslint-disable-next-line no-undef
          const fromDate = moment().subtract(days, "days").format(format);
          // eslint-disable-next-line no-undef
          const toDate = moment().format(format);
          return fromDate + " to " + toDate;
        },
        $getSum(array, field) {
          return array.reduce(function (sum, current) {
            return Number(sum) + Number(current[field]);
          }, 0);
        },
        $globalEmit(event, params) {
          EventBus.$emit(event, params);
        },
        $headMeta: function (key) {
          const meta = document.head.querySelector('meta[name="' + key + '"]');
          return meta ? meta.content : "";
        },
        $pluck(array, key) {
          return array.map((o) => o[key]);
        },
      },
      beforeDestroy() {},
      mounted() {},
      created() {},
    });
  },
};

export default GlobalMixins;
