<template>
  <transition name="fade" mode="out-in">
    <div class="pt-6 px-2">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </transition>
</template>

<script>
export default {};
</script>

<style scoped></style>
