<template>
  <div class="row">
    <div v-if="!success" class="col-12">
      <h5 class="text-center">Enter the Contest</h5>
    </div>
    <div v-if="!success" class="col-12">
      <b-overlay :show="loading" rounded="sm">
        <b-form @submit.prevent="onCustomerSubmit">
          <div class="mb-3">
            <label for="input-birthday">Birthday *</label>
            <date-picker
              id="input-birthday"
              v-model="contestCustomer.birthday"
              :config="options"
              required
            ></date-picker>
          </div>
          <b-form-group
            id="input-group-mobile"
            label="Mobile Number *"
            label-for="input-mobile"
          >
            <b-form-input
              id="input-mobile"
              v-model="contestCustomer.mobile"
              type="text"
              placeholder="Enter mobile number"
              v-mask="mask"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-name"
            label="Full Name *"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="contestCustomer.display_name"
              type="text"
              placeholder="Enter full name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-email"
            label="Email address *"
            label-for="input-email"
          >
            <b-form-input
              id="input-email"
              v-model="contestCustomer.email"
              type="email"
              placeholder="Enter email"
              required
            ></b-form-input>
          </b-form-group>
          <div class="text-center">
            <p>
              <strong>AGREEMENT</strong><br />By entering this contest, you are
              agreeing that you are above 18 years old.
            </p>
            <b-button type="submit" variant="primary">Enter Contest</b-button>
          </div>
        </b-form>
      </b-overlay>
      <h4 v-if="error" class="text-center text-danger mt-3">
        You are already registered with us.
      </h4>
    </div>
    <div v-if="success" class="col-12">
      <div class="text-center pt-5">
        <i class="material-icons success-icon text-success mb-5">verified</i>
        <h5>Thank you for entering the Contest.</h5>
        <p>You will be notified the result by SMS.</p>
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import moment from "moment";
export default {
  name: "Contest",
  components: {
    datePicker,
  },
  data() {
    return {
      error: false,
      isLoading: false,
      success: false,
      options: {
        format: "DD/MM/YYYY",
        // eslint-disable-next-line no-undef
        maxDate: moment().subtract(18, "years"),
        date: moment().subtract(18, "years"),
      },
      contestCustomer: this.defaultCustomerObj(),
    };
  },
  computed: {
    loading: {
      get() {
        return this.isLoading;
      },
      set(v) {
        this.isLoading = v;
      },
    },
    mask() {
      return process.env.VUE_APP_MOBILE_MASK;
    },
  },
  methods: {
    onCustomerSubmit() {
      this.loading = true;
      const payload = _.cloneDeep(this.contestCustomer);
      axios
        .post("/customer", payload)
        .then(() => {
          this.success = true;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    defaultCustomerObj() {
      return {
        mobile: null,
        display_name: null,
        email: null,
        birthday: null,
        status: 1,
        contest: true,
      };
    },
    reset() {
      this.contestCustomer = this.defaultCustomerObj();
      this.error = false;
      this.isLoading = false;
      this.success = false;
    },
  },
  created() {
    this.reset();
  },
};
</script>

<style scoped>
.success-icon {
  font-size: 90px;
}
</style>
