<template>
  <b-navbar fixed="top" variant="light" class="shadow pr-0">
    <b-navbar-brand href="#" class="text-primary">TIPSY LIQUOR</b-navbar-brand>

    <b-nav class="ml-auto">
      <b-nav-item class="border-right" to="/" right>
        <i class="material-icons text-primary"> liquor </i>
      </b-nav-item>
      <b-nav-item
        to="/cart"
        class="cart-nav"
        :class="hasCartItems ? 'border-right' : ''"
        right
      >
        <div v-if="hasCartItems" class="item-count">
          {{ cartItemCount }}
        </div>
        <i class="material-icons text-primary"> shopping_cart </i>
      </b-nav-item>
      <b-nav-item v-if="hasCartItems" to="/checkout" right>
        <i class="material-icons text-primary"> point_of_sale </i>
      </b-nav-item>
    </b-nav>
  </b-navbar>
</template>

<script>
export default {
  name: "TopBar",
};
</script>

<style scoped>
.cart-nav {
  position: relative;
}
.cart-nav .item-count {
  position: absolute;
  top: -3px;
  right: 3px;
  padding: 0 7px;
  border-radius: 20px;
  color: #fff;
  background-color: #333;
  font-weight: bold;
}
</style>
