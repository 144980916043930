<template>
  <div class="row">
    <div class="col-12">
      <h4 class="text-center">Checkout</h4>
    </div>
    <div class="col-12">
      <div class="accordion" role="tablist">
        <b-card no-body>
          <b-card-header
            header-tag="header"
            class="p-1 text-center align-content-center"
            role="tab"
          >
            <div>
              Order Type<a
                v-if="canEditOrderType"
                @click.prevent="handleEditOrderType"
                href="#"
                class="edit-btn"
                >Edit</a
              >
            </div>
          </b-card-header>
          <b-collapse
            id="order-type"
            :visible="isOrderType"
            accordion="order-type"
            role="tabpanel"
          >
            <b-card-body>
              <b-overlay :show="loading" rounded="sm">
                <b-form @submit.prevent="onOrderTypeSubmit">
                  <b-form-checkbox
                    class="text-center mb-3"
                    v-model="ageConfirmation"
                    unchecked-value="not_accepted"
                    value="accepted"
                    >I confirm that I am over the age of 18.</b-form-checkbox
                  >
                  <b-form-radio-group
                    id="radio-order-types"
                    class="text-center mb-3"
                    v-model="type"
                    :options="masters.orderTypes"
                    name="radio-order-types"
                  ></b-form-radio-group>
                  <div class="text-center">
                    <b-button
                      type="submit"
                      :disabled="
                        ageConfirmation === 'not_accepted' || type === null
                      "
                      variant="primary"
                      >Next</b-button
                    >
                  </div>
                </b-form>
              </b-overlay>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body>
          <b-overlay :show="loading" rounded="sm">
            <b-card-header
              header-tag="header"
              class="p-1 text-center align-content-center"
              role="tab"
            >
              <div>Mobile Verification</div>
            </b-card-header>
            <b-collapse
              id="mobile-verify"
              :visible="isMobileVerify"
              accordion="mobile-verify"
              role="tabpanel"
            >
              <b-card-body>
                <b-form @submit.prevent="onMobileVerifySubmit">
                  <b-form-group
                    id="input-group-mobile"
                    label="Mobile Number"
                    label-for="input-mobile"
                  >
                    <b-form-input
                      id="input-mobile"
                      :value="customer.mobile"
                      @input="
                        setValue({ key: 'customer.mobile', value: $event })
                      "
                      type="text"
                      placeholder="Enter mobile number"
                      v-mask="mask"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="input-group-otp"
                    label="OTP"
                    label-for="input-otp"
                    v-if="otpRequested"
                  >
                    <b-form-input
                      id="input-otp"
                      v-model="otp"
                      type="number"
                      placeholder="Enter OTP"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-button v-if="otpRequested" type="submit" class="pull-right"
                    >Login</b-button
                  >
                  <b-button
                    v-if="canRequestOtp"
                    class="mr-2"
                    @click="onRequestOtp"
                    >Request OTP</b-button
                  >
                </b-form>
              </b-card-body>
            </b-collapse>
          </b-overlay>
        </b-card>
        <b-card no-body>
          <b-card-header
            header-tag="header"
            class="p-1 text-center align-content-center"
            role="tab"
          >
            <div>
              Customer Details<a
                v-if="canEditCustomerInfo"
                href="#"
                class="edit-btn"
                >Edit</a
              >
            </div>
          </b-card-header>
          <b-collapse
            id="customer-info"
            :visible="isCustomerInfo"
            accordion="customer-info"
            role="tabpanel"
          >
            <b-card-body>
              <b-form @submit.prevent="onCustomerSubmit">
                <b-form-group
                  id="input-group-name"
                  label="Full Name"
                  label-for="input-name"
                >
                  <b-form-input
                    id="input-name"
                    :value="customer.display_name"
                    @input="
                      setValue({ key: 'customer.display_name', value: $event })
                    "
                    type="text"
                    placeholder="Enter full name"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-email"
                  label="Email address"
                  label-for="input-email"
                >
                  <b-form-input
                    id="input-email"
                    :value="customer.email"
                    @input="setValue({ key: 'customer.email', value: $event })"
                    type="email"
                    placeholder="Enter email"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-address"
                  label="Address"
                  label-for="input-address"
                  v-if="isDelivery"
                >
                  <b-form-input
                    id="input-address"
                    :value="customer.address.address_1"
                    @input="
                      setValue({
                        key: 'customer.address.address_1',
                        value: $event,
                      })
                    "
                    type="text"
                    placeholder="Enter address"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-postal-code"
                  label="Postal Code"
                  label-for="input-postal-code"
                  description="Please note: We currently only deliver to postal codes starting with T4X"
                  v-if="isDelivery"
                >
                  <b-form-input
                    id="input-postal-code"
                    :state="null"
                    :value="customer.address.postal_code"
                    @input="
                      setValue({
                        key: 'customer.address.postal_code',
                        value: $event,
                      })
                    "
                    v-mask="postalMask"
                    type="text"
                    placeholder="Enter Postal Code"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="text-center">
                  <b-button type="submit" variant="primary">Next</b-button>
                </div>
              </b-form>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body>
          <b-card-header
            header-tag="header"
            class="p-1 text-center align-content-center"
            role="tab"
          >
            <div>Order Summary</div>
          </b-card-header>
          <b-collapse
            id="order-summary"
            :visible="isOrderSummary"
            accordion="order-summary"
            role="tabpanel"
          >
            <b-card-body>
              <b-form @submit.prevent="onOrderSubmit">
                <div class="row">
                  <div class="col-12 mb-3">
                    <h5>Order Details</h5>
                    <p class="mb-2">
                      <small>Type</small><br />{{ $capitalize(type) }}
                    </p>
                    <p v-if="isDelivery" class="mb-2 text-danger">
                      <small>Please Note</small><br />Order placed between 10:00
                      AM and 5:00 PM will be delivered after 5:00 PM onwards.
                    </p>
                  </div>
                  <div class="col-12 mb-3">
                    <h5>Customer Details</h5>
                    <p class="mb-2">
                      <small>Name</small><br />{{ customer.display_name }}
                    </p>
                    <p class="mb-2">
                      <small>Mobile</small><br />{{ customer.mobile }}
                    </p>
                    <p v-if="isDelivery" class="mb-2">
                      <small>Address</small><br />{{
                        customer.address.address_1
                      }}
                    </p>
                    <p v-if="isDelivery" class="mb-2">
                      <small>Postal Code</small><br />{{
                        customer.address.postal_code
                      }}
                    </p>
                  </div>
                  <div class="col-12">
                    <div class="table-responsive">
                      <table class="table table-sm">
                        <tbody v-if="hasCartItems">
                          <tr v-for="s in cartItems" :key="s.id">
                            <td>
                              {{ s.title }}<br /><small>{{
                                s.variant_title
                              }}</small>
                              <small v-if="s.deposit_amount > 0"
                                ><br />Deposit {{ s.deposit_rate }} x
                                {{ s.quantity }} =
                                {{ $moneyfy(s.deposit_amount) }}</small
                              >
                            </td>
                            <td>{{ s.rate }} x {{ s.quantity }}</td>
                            <td class="text-right">
                              {{ $moneyfy(s.amount) }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-if="!hasCartItems">
                          <tr>
                            <td colspan="4" class="text-center">
                              Cart is empty
                            </td>
                          </tr>
                        </tbody>
                        <tfoot v-if="hasCartItems">
                          <tr>
                            <th colspan="2" class="text-right">
                              <small>SUB-TOTAL</small>
                            </th>
                            <th class="text-right">
                              <small>{{ $moneyfy(totals.subTotal) }}</small>
                            </th>
                          </tr>
                          <tr>
                            <th colspan="2" class="text-right">
                              <small>TAX ({{ totals.taxRate }}%)</small>
                            </th>
                            <th class="text-right">
                              <small>{{ $moneyfy(totals.taxTotal) }}</small>
                            </th>
                          </tr>
                          <tr>
                            <th colspan="2" class="text-right">
                              <small>DEPOSIT TOTAL</small>
                            </th>
                            <th class="text-right">
                              <small>{{ $moneyfy(totals.depositTotal) }}</small>
                            </th>
                          </tr>
                          <tr>
                            <th colspan="2" class="text-right">
                              <strong>PAYABLE</strong>
                            </th>
                            <th class="text-right">
                              <strong>{{ $moneyfy(totals.grandTotal) }}</strong>
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div v-if="type !== null" class="col-12 text-center">
                    <PayPal
                      :amount="paypalAmount"
                      :details="paypalDetails"
                      :currency="currency"
                      :client="paypal"
                      :env="ppEnv"
                      :items="ppalItems"
                      :button-style="ppButton"
                      @payment-authorized="onOrderSubmit"
                      class="mb-3"
                    >
                    </PayPal>
                  </div>
                </div>
              </b-form>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body>
          <b-card-header
            header-tag="header"
            class="p-1 text-center align-content-center"
            role="tab"
          >
            <div>Order Success</div>
          </b-card-header>
          <b-collapse
            id="order-success"
            :visible="isOrderSuccess"
            accordion="order-success"
            role="tabpanel"
          >
            <b-card-body>
              <div class="text-center">
                <i class="material-icons success-icon text-success">verified</i>
                <h5>Thank you for placing order with us.</h5>
                <p>You may close the dialog.</p>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import PayPal from "vue-paypal-checkout";
export default {
  name: "Checkout",
  components: {
    PayPal,
  },
  data() {
    return {
      isLoading: false,
      paypal: {
        sandbox: process.env.VUE_APP_PAYPAL_SANDBOX,
        production: process.env.VUE_APP_PAYPAL_PROD,
      },
      ppalItems: [],
      masters: {
        orderTypes: [
          { value: "pickup", text: "Pick-up" },
          { value: "delivery", text: "Delivery" },
        ],
      },
      operation: "order-type",
      otpRequestAvailable: true,
      otpRequested: false,
      otp: null,
      updateCustomer: false,
    };
  },
  computed: {
    loading: {
      get() {
        return this.isLoading;
      },
      set(v) {
        this.isLoading = v;
      },
    },
    ageConfirmation: {
      get() {
        return this.ageVerification;
      },
      set(value) {
        this.setValue({ key: "ageVerification", value: value });
      },
    },
    type: {
      get() {
        return this.orderType;
      },
      set(value) {
        this.setValue({ key: "orderType", value: value });
      },
    },
    canRequestOtp() {
      return this.otpRequestAvailable;
    },
    mask() {
      return process.env.VUE_APP_MOBILE_MASK;
    },
    postalMask() {
      return process.env.VUE_APP_POSTAL_MASK;
    },
    currency() {
      return process.env.VUE_APP_CURRENCY;
    },
    paypalAmount() {
      return this.totals.grandTotal.toFixed(2);
    },
    paypalDetails() {
      return {
        subtotal: this.totals.subTotal.toFixed(2),
        handling_fee: Number(this.totals.depositTotal).toFixed(2),
        tax: Number(this.totals.taxTotal).toFixed(2),
      };
    },
    ppEnv() {
      return process.env.NODE_ENV === "development" ? "sandbox" : "production";
    },
    ppButton() {
      return {
        label: "paypal",
        size: "responsive", // small | medium | large | responsive
        shape: "rect", // pill | rect
        color: "black", // gold | blue | silver | black,
        tagline: false,
        layout: "vertical",
      };
    },
    isDelivery() {
      return this.type === "delivery";
    },
    isPickup() {
      return this.type === "pickup";
    },
    existingCustomer() {
      return this.customer.id !== null;
    },
    isOrderType() {
      return this.operation === "order-type";
    },
    isMobileVerify() {
      return this.operation === "mobile-verify";
    },
    isCustomerInfo() {
      return this.operation === "customer-info";
    },
    isOrderSummary() {
      return this.operation === "order-summary";
    },
    isOrderSuccess() {
      return this.operation === "order-success";
    },
    canEditOrderType() {
      return !this.isOrderType;
    },
    canEditCustomerInfo() {
      return (
        !this.isCustomerInfo && this.type !== null && this.customer.id !== null
      );
    },
  },
  methods: {
    handleEditOrderType() {
      this.operation = "order-type";
    },
    onOrderTypeSubmit() {
      this.operation = this.existingCustomer
        ? "customer-info"
        : "mobile-verify";
    },
    onRequestOtp() {
      this.loading = true;
      const payload = {
        mobile: this.customer.mobile,
      };
      axios
        .post("request-otp", payload)
        .then((res) => {
          this.otpRequested = res.data.status;
          this.setOtpButton();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setOtpButton() {
      this.otpRequestAvailable = false;
      setTimeout(() => (this.otpRequestAvailable = true), 10000);
    },
    onMobileVerifySubmit() {
      this.loading = true;
      const payload = {
        mobile: this.customer.mobile,
        otp: this.otp,
      };
      axios
        .post("verify-otp", payload)
        .then((res) => {
          if (res.data.status === true) {
            if (res.data.existing) {
              this.setCustomer(res.data);
            }
            this.operation = "customer-info";
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCustomerSubmit() {
      if (this.isDelivery) {
        const postalCode = this.customer.address.postal_code.toUpperCase();
        if (postalCode.match("^T4X-[0-9][A-Z][0-9]") === null) {
          alert("Sorry, we do not deliver to the postal you just provided.");
          return false;
        }
      }
      if (!this.existingCustomer) {
        const entry = _.cloneDeep(this.customer);
        let params = objectToFormData(entry, {
          indices: true,
          booleansAsIntegers: true,
        });
        //params.set("_method", "PUT");
        axios.post("customer", params).then((res) => {
          this.setCustomer(res.data);
          this.operation = "order-summary";
        });
      } else {
        this.operation = "order-summary";
      }
    },
    onOrderSubmit(res) {
      this.setValue({ key: "cart.totals.payment", value: res });
      const payload = {
        type: this.type,
        customer_id: this.customer.id,
        display_name: this.customer.display_name,
        address: this.customer.address.address_1,
        postal_code: this.customer.address.postal_code,
        email: this.customer.email,
        phone: this.customer.mobile,
        alt_phone: null,
        discount_type: this.totals.discountType,
        tax_rate: this.totals.taxRate,
        discount_rate: null,
        delivery_total: this.totals.deliveryTotal,
        tax_total: this.totals.taxTotal,
        discount_total: this.totals.discountTotal,
        change_total: this.totals.changeTotal,
        adjustment_total: this.totals.adjustmentTotal,
        tip_total: this.totals.tipTotal,
        deposit_total: this.totals.depositTotal,
        sub_total: this.totals.subTotal,
        grand_total: this.totals.grandTotal,
        items: this.cartItems,
        payment: this.totals.payment,
      };
      axios.post("order", payload).then(() => {
        this.resetOrder();
        this.operation = "order-success";
      });
    },
    blankPpalItem(item) {
      let name = item.title;
      if (item.variant_title) {
        name += " - " + item.variant_title;
      }
      return {
        name: name,
        description: name,
        quantity: item.quantity.toString(),
        price: item.rate.toString(),
        currency: this.currency,
      };
    },
  },
  created() {
    if (!this.hasCartItems) {
      this.$router.push("/");
    }
    this.ppalItems = [];
    this.cartItems.forEach((s) => {
      this.ppalItems.push(this.blankPpalItem(s));
    });
  },
};
</script>

<style scoped>
.success-icon {
  font-size: 64px;
}
.edit-btn {
  float: right;
  margin-right: 5px;
}
</style>
