import { mapActions, mapGetters } from "vuex";

const OrderMixins = {
  install(Vue) {
    Vue.mixin({
      computed: {
        ...mapGetters("Order", [
          "cartItems",
          "totals",
          "customer",
          "orderType",
          "ageVerification",
          "isLoggedIn",
        ]),
        cartItemCount() {
          return this.cartItems.length;
        },
        hasCartItems() {
          return this.cartItemCount > 0;
        },
      },
      methods: {
        ...mapActions("Order", [
          "setValue",
          "pushValue",
          "pullIdValue",
          "calculateTotal",
          "resetState",
        ]),
        setupValues() {
          this.setValue({ value: 5, key: "cart.totals.taxRate" });
          this.setupCustomer();
          this.setupCart();
          /*this.customer = localStorage.getItem("user");
          if (items) {
            this.cartItems = JSON.parse(items);
          }*/
        },
        setupCustomer() {
          const key = localStorage.getItem("key");
          const cid = localStorage.getItem("cid");
          if (key && cid) {
            const payload = { cid: cid, key: key };
            axios.post("/validate-login", payload).then((res) => {
              if (res.data.status) {
                this.setCustomer(res.data);
              }
            });
          } else {
            localStorage.removeItem("key");
            localStorage.removeItem("cid");
          }
        },
        setupCart() {
          let items = JSON.parse(localStorage.getItem("retailCart"));
          if (items === null) {
            items = [];
            localStorage.setItem("retailCart", JSON.stringify(items));
          }
          this.setValue({ value: items, key: "cart.items" });
          this.recalculate();
        },
        setCustomer(data) {
          const customer = data.customer;
          customer.address = { address_1: null, postal_code: null };
          if (customer.addresses.length) {
            customer.address.address_1 = customer.addesses[0].address_1;
            customer.address.postal_code = customer.addesses[0].postal_code;
          }
          delete customer.addresses;
          this.setValue({ key: "customer", value: customer });
          this.setValue({ key: "key", value: data.key });
          localStorage.setItem("customer", JSON.stringify(customer));
          localStorage.setItem("key", data.key);
          localStorage.setItem("cid", data.customer.id);
        },
        addToCart(item) {
          const existingIdx = this.getCartItemIndex(item.item_id);
          if (existingIdx === -1) {
            this.pushValue({ key: "cart.items", value: item });
          } else {
            const items = _.cloneDeep(this.cartItems);
            items[existingIdx].quantity =
              Number(items[existingIdx].quantity) + Number(item.quantity);
            items[existingIdx].amount =
              Number(items[existingIdx].quantity) *
              Number(items[existingIdx].rate);
            this.setValue({ value: items, key: "cart.items" });
          }
          this.setCartLocalStorage(this.cartItems);
          this.recalculate();
        },
        removeCartItem(v) {
          this.pullIdValue({ key: "cart.items", value: v, field: "item_id" });
          this.setCartLocalStorage(this.cartItems);
          this.recalculate();
        },
        $clearCart() {
          const items = [];
          this.setCartLocalStorage([]);
          this.setValue({ value: items, key: "cart.items" });
          this.recalculate();
        },
        setCartLocalStorage(value) {
          localStorage.setItem("retailCart", JSON.stringify(value));
        },
        recalculate() {
          this.calculateTotal();
        },
        getCartItem(item_id) {
          const idx = this.getCartItemIndex(item_id);
          return idx !== -1 ? this.cartItems[idx] : null;
        },
        getCartItemIndex(item_id) {
          return this.cartItems.findIndex((s) => {
            return Number(s.item_id) === Number(item_id);
          });
        },
        resetOrder() {
          this.$clearCart();
          this.resetState();
        },
      },
    });
  },
};

export default OrderMixins;
