import Vue from "vue";
import Vuex from "vuex";

import Order from "./store/order";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    Order,
  },
  strict: debug,
});
