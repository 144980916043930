window._ = require("lodash");
window.objectToFormData = require("object-to-formdata").objectToFormData;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
/*window.axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 419) {
      location.assign('/login')
    }

    return Promise.reject(error)
  }
)*/
