<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="row no-gutters">
      <div class="col-12 mb-2"></div>
      <div class="col-12">
        <product-dialog></product-dialog>
        <div class="row">
          <div class="col-md-6 col-12 mb-3">
            <input
              type="text"
              class="form-control"
              v-model="search.s"
              placeholder="Search products..."
            />
          </div>
          <div class="col-md-6 col-12 mb-3">
            <select v-model="search.category_id" class="form-control">
              <option :value="null">All Drink Types</option>
              <option :key="c.id" v-for="c in computedCategories" :value="c.id">
                {{ c.name }}
              </option>
            </select>
          </div>
        </div>
        <b-list-group>
          <b-list-group-item
            v-for="s in computedItems"
            :key="s.id"
            href="#"
            @click.prevent="onItemClick(s)"
            class="flex-column align-items-start"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ s.name }}</h5>
              <small>{{
                s.status !== "out_of_stock"
                  ? $moneyfy(getPrice(s))
                  : "Out of Stock"
              }}</small>
            </div>
            <p v-if="hasVariants" class="m-0">
              <small>{{ getVariantNames(s) }}</small>
            </p>
            <p class="m-0">
              <small>{{ s.description }}</small>
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ProductDialog from "./Dialog";
export default {
  name: "ProductList",
  components: {
    ProductDialog,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      categories: [],
      search: {
        s: "",
        category_id: null,
      },
      query: { sort: "order_number", order: "desc", limit: 100, s: "" },
    };
  },
  computed: {
    loading() {
      return this.isLoading;
    },
    computedItems() {
      return this.items.filter((s) => {
        const str = s.name.toLowerCase();
        if (this.search.category_id === null) {
          return str.indexOf(this.search.s.toLowerCase()) !== -1;
        } else {
          return (
            str.indexOf(this.search.s.toLowerCase()) !== -1 &&
            s.category_id.indexOf(this.search.category_id) !== -1
          );
        }
      });
    },
    computedCategories() {
      return this.categories;
    },
  },
  methods: {
    fetchProducts() {
      this.isLoading = true;
      // eslint-disable-next-line no-undef
      axios
        .get("/items")
        .then((res) => {
          this.items = res.data.items;
          this.categories = res.data.categories;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onItemClick(s) {
      this.$globalEmit("init-modal-variant-dialog", s);
    },
    hasVariants(s) {
      return s.variants.length > 0;
    },
    getVariantNames(s) {
      const fields = this.$pluck(s.variants, "name");
      return fields.join(", ", fields);
    },
    getPrice(s) {
      let rate = s.rate;
      if (s.variants.length) {
        rate = s.variants[0].rate.toFixed(2);
      }
      return rate ? Number(rate).toFixed(2) : Number(0).toFixed(2);
    },
  },
  created() {
    this.fetchProducts();
  },
};
</script>

<style scoped></style>
