<template>
  <div id="app" class="container">
    <top-bar></top-bar>
    <main-content></main-content>
  </div>
</template>

<script>
import TopBar from "./components/TopBar";
import MainContent from "./components/Content";

export default {
  name: "App",
  components: {
    TopBar,
    MainContent,
  },
};
</script>

<style scoped></style>
